
.img-fluid {
    width: 100%; 
    height: auto; 
  }
  
  .member-img {
    position: relative;
  }
  
  .member-img img {
    width: 100%;
    height: 300px;
    object-fit: cover; 
  }
  