.application-form {
  background-color: #05147f;
}

.hero-area {
  background-color: #05147f; 
  padding: 20px;
}

.main-title {
  color: #2596be;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
}

.location-info {
  text-align: center;
  color: #333;
  font-size: 18px;
}

.content-container {
  padding: 20px 50px;
}

.info-section, .fees-section, .contact-section {
  margin-top: 30px;
}
.info-card {
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}
.application-form .info-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.application-form .info-card:active {
  transform: scale(0.98); 
}

.application-form .info-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
/* ApplicationForm.css */

.application-form .btn-primary {
  background-color: #007bff; /* Change the color if needed */
  border: none;
}

.application-form .btn-primary:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transition: background-color 0.3s ease;
}
.btn-custom {
  padding: 5px 10px; /* Adjust padding as needed */
  font-size: 14px;   /* Adjust font size as needed */
  height: auto;  
      /* Adjust height as needed */
}

.extra-small-button {
  padding: 2px 8px; /* Smaller padding */
  font-size: 12px; /* Smaller font size */
  border-width: 1px; /* Thinner border */
  width: 40%;
}
