
.header_section {
  /* background-color: #fff; */
  padding: px 0;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Container styles */
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 7px;
}

/* Logo styling */
.logo {
  flex: 0 1 auto;
}

.logo img {
  max-width: 100px;
}

/* Navbar styling */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar-menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.nav-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: 5em;
  list-style: none;
}

.nav-list .ant-menu-item {
  margin: 0 5px;
}

.nav-list .ant-menu-item a {
  color: #333;
  font-weight: 500;
  text-decoration: none;
}

/* Hide menu-toggle button on screens larger than 768px */
@media (min-width: 768px) {
  .menu-toggle {
    display: none;
  }
}

/* Drawer styling for mobile */
.ant-drawer-content {
  /* background-color: #f5f5f5; */
}
